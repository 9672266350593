<template>
  <div class="step-content important-event-category">
    <div class="memorial-profile-add-edit-form-category-header">
      <h2 class="memorial-profile-add-edit-form-category-name">Dodatkowy opis</h2>

      <button class="section-toggle" @click="toggleShowSection">
        <span>{{ isSectionOpen ? 'Zwiń sekcję' : 'Rozwiń sekcję' }}</span>

        <v-icon>{{ isSectionOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </button>
    </div>

    <div v-if="isSectionOpen">
      <div class="horizontal-separator"></div>

      <p class="memorial-profile-add-edit-form-category-description">Sekcja tego typu służy do opisania dowolnego
        aspektu z życia osoby. Może zawierać zdjęcie, tytuł i opis.</p>

      <div class="horizontal-separator"></div>

      <div v-for="(sectionItem, index) in category?.items" :key="sectionItem.id">
        <div class="memorial-profile-add-edit-form-category-item-header-wrapper">
          <p class="memorial-profile-add-edit-form-category-item-header">Dodatkowy opis - wpis nr {{ index + 1 }}</p>

          <v-btn
              class="memorial-profile-add-edit-form-category-delete-button"
              prepend-icon="mdi-delete-outline"
              @click="removeCategoryItem(sectionItem.id)"
          >Usuń
          </v-btn>
        </div>

        <p class="memorial-profile-add-edit-form-category-paragraph">Dodaj zdjęcie nawiązujące do kategorii
          (opcjonalnie)</p>

        <DragAndDropField
            :photo-url="sectionItem.photoUrl"
            @files-changed="handleFilesChange($event, sectionItem?.id)"
            @image-deleted="handleDeleteImage($event, sectionItem?.id)"
        />

        <div class="dates-inline-wrapper">
          <v-text-field
              v-model="sectionItem.day"
              :rules="dayRules"
              label="Dzień"
              @change="v$.dayErrors?.$touch"
          />
          <v-text-field
              v-model="sectionItem.month"
              :rules="monthRules"
              label="Miesiąc"
              @change="v$.monthErrors?.$touch"
          />
          <v-text-field
              v-model="sectionItem.year"
              :rules="yearRules"
              label="Rok"
              @change="v$.yearErrors?.$touch"
          >
            <template #label>
              <span>Rok</span>
            </template>
          </v-text-field>
        </div>

        <v-text-field v-model="sectionItem.title" label="Tytuł"/>

        <v-textarea v-model="sectionItem.description" label="Opis"/>
      </div>

      <v-btn
          append-icon="mdi-plus"
          class="memorial-profile-add-edit-form-category-add-item-button"
          @click="addCategoryItem"
      >Dodaj kolejny dodatkowy opis
      </v-btn>

      <div class="horizontal-separator"></div>

      <div class="memorial-profile-add-edit-form-category-footer-buttons">
        <v-btn
            class="memorial-profile-add-edit-form-category-delete-button"
            prepend-icon="mdi-delete-outline"
            @click="removeSection"
        >Usuń sekcję
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import DragAndDropField from "@/components/DragAndDropField.vue";
import {useMemorialProfileStore} from "@/stores/memorial-profile";

export default {
  name: 'ImportantEventCategory',
  components: {DragAndDropField},
  props: {
    sections: {
      type: Array,
      required: true,
    },
    category: {
      type: Object,
      required: true,
      default: () => ({
        id: null,
        key: 'important_events',
        items: [{
          day: String,
          month: String,
          year: String,
          title: String,
          description: String,
          photoUrl: String,
        }],
      }),
    },
  },
  emits: ['section-updated'],
  setup() {
    const v$ = useVuelidate()
    const store = useMemorialProfileStore();
    return { v$, store };
  },
  data() {
    return {
      categoryName: 'additional_description',
      isSectionOpen: true,
    }
  },
  computed: {
    dayRules() {
      return [
        v => !v || (v >= 1 && v <= 31) || 'Dzień musi być liczbą pomiędzy 1 a 31.',
      ];
    },
    monthRules() {
      return [
        v => !v || (v >= 1 && v <= 12) || 'Miesiąc musi być liczbą pomiędzy 1 a 12.',
      ];
    },
    yearRules() {
      return [
        v => !v || (v.length === 4) || 'Rok musi składać się z 4 cyfr.',
        v => !v || !isNaN(v) || 'Rok musi być liczbą.',
      ];
    },
  },
  methods: {
    hideApiErrors() {
      this.isError = false;
    },
    handleFilesChange({ files }, sectionItemId) {
      if (!files?.length) {
        return;
      }

      const file = files[0];

      this.store.uploadImage({ file, categoryName: this.categoryName, sectionItemId });
    },
    handleDeleteImage({ photoUrl }, sectionItemId) {
      this.store.deleteImage({ photoUrl, categoryName: this.categoryName, sectionItemId });
    },
    toggleShowSection() {
      this.isSectionOpen = !this.isSectionOpen;
    },
    addCategoryItem() {
      if (!this.category) {
        return;
      }

      this.store.addSectionItemState(this.categoryName);
      this.$emit('section-updated');
    },
    removeSection() {
      this.store.removeSectionState(this.category.id);
      this.$emit('section-updated');
    },
    removeCategoryItem(itemId) {
      this.store.removeSectionItemState(this.category.id, itemId);
      this.$emit('section-updated');
    },
  },
}
</script>

<style scoped>
</style>
