<template>
  <div class="step-content education-category">
    <div class="memorial-profile-add-edit-form-category-header">
      <h2 class="memorial-profile-add-edit-form-category-name">Edukacja</h2>

      <button class="section-toggle" @click="toggleShowSection">
        <span>{{ isSectionOpen ? 'Zwiń sekcję' : 'Rozwiń sekcję' }}</span>

        <v-icon>{{ isSectionOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </button>
    </div>

    <div v-if="isSectionOpen">
      <div class="horizontal-separator"></div>

      <p class="memorial-profile-add-edit-form-category-description">Kategoria edukacja może być wykorzystana do
        opisania różnych etapów kształcenia takich jak okres szkoły lub studiów.</p>

      <div class="horizontal-separator"></div>

      <div v-for="(sectionItem, index) in category?.items" :key="sectionItem?.id">
        <div v-if="sectionItem">
          <div class="memorial-profile-add-edit-form-category-item-header-wrapper">
            <p class="memorial-profile-add-edit-form-category-item-header">Edukacja - wpis nr {{ index + 1 }}</p>

            <v-btn
                class="memorial-profile-add-edit-form-category-delete-button"
                prepend-icon="mdi-delete-outline"
                @click="removeCategoryItem(sectionItem.id)"
            >Usuń
            </v-btn>
          </div>

          <p class="memorial-profile-add-edit-form-category-paragraph">Dodaj zdjęcie nawiązujące do kategorii
            (opcjonalnie)</p>

          <DragAndDropField
              :photo-url="sectionItem.photoUrl"
              @files-changed="handleFilesChange($event, sectionItem?.id)"
              @image-deleted="handleDeleteImage($event, sectionItem?.id)"
          />

          <div class="dates-inline-wrapper">
            <v-text-field v-model="sectionItem.startYear" label="Rok rozpoczęcia" type="text"></v-text-field>
            <v-text-field v-model="sectionItem.endYear" label="Rok zakończenia" type="text"></v-text-field>
          </div>

          <v-text-field v-model="sectionItem.place" label="Miejsce nauki" prepend-inner-icon="mdi-school"/>

          <v-textarea v-model="sectionItem.description" label="Opis"></v-textarea>
        </div>
      </div>

      <v-btn
          append-icon="mdi-plus"
          class="memorial-profile-add-edit-form-category-add-item-button"
          @click="addCategoryItem"
      >Dodaj kolejny wpis o edukacji
      </v-btn>

      <div class="horizontal-separator"></div>

      <div class="memorial-profile-add-edit-form-category-footer-buttons">
        <v-btn
            class="memorial-profile-add-edit-form-category-delete-button"
            prepend-icon="mdi-delete-outline"
            @click="removeSection"
        >Usuń sekcję
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import DragAndDropField from "@/components/DragAndDropField.vue";
import { useMemorialProfileStore } from "@/stores/memorial-profile";

export default {
  components: { DragAndDropField },
  props: {
    sections: {
      type: Array,
      required: true,
    },
    category: {
      type: Object,
      required: true,
    },

  },
  emits: ['section-updated'],
  setup() {
    const store = useMemorialProfileStore();
    return { store };
  },
  data() {
    return {
      categoryName: 'education',
      isSectionOpen: true,
    }
  },
  methods: {
    toggleShowSection() {
      this.isSectionOpen = !this.isSectionOpen;
    },
    addCategoryItem() {
      if (!this.category) {
        return;
      }
      this.store.addSectionItemState(this.categoryName);
      this.$emit('section-updated');
    },
    removeSection() {
      this.store.removeSectionState(this.category.id);
      this.$emit('section-updated');
    },
    removeCategoryItem(itemId) {
      this.store.removeSectionItemState(this.category.id, itemId);
      this.$emit('section-updated');
    },
    handleFilesChange({ files }, sectionItemId) {
      if (!files?.length) {
        return;
      }

      const file = files[0];

      this.store.uploadImage({ file, categoryName: this.categoryName, sectionItemId });
    },
    handleDeleteImage({ photoUrl }, sectionItemId) {
      this.store.deleteImage({ photoUrl, categoryName: this.categoryName, sectionItemId });
    },
  },
}
</script>

<style scoped>
</style>
